var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.documentDataLoading),expression:"documentDataLoading"}],staticClass:"employee-document-authorize"},[_c('div',{staticClass:"inner-navbar"},[_c('el-row',{attrs:{"type":"flex","align":"middle","justify":"center"}},[_c('el-col',{attrs:{"xl":{ span: 7 },"lg":{ span: 8 },"md":{ span: 12 },"sm":{ span: 16 }}},[_c('div',{staticClass:"text-center mt-2"},[_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":"Logo","width":"220"}})]),_c('el-card',{staticClass:"mt-2",attrs:{"shadow":"never"}},[_c('h2',{staticClass:"font-weight-normal blue-2 text-center pt-05"},[_vm._v(" Access Document ")]),_c('div',[_c('div',{staticClass:"mt-05",attrs:{"shadow":"never"}},[_c('el-form',[_c('el-form-item',{attrs:{"label":"Enter the password recieve at your e-mail id"}},[_c('el-input',{attrs:{"placeholder":"Ex: 23DR2C"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),(
                    _vm.getAuthorizedRepresentativeVerifyPasswordErrors &&
                    _vm.getAuthorizedRepresentativeVerifyPasswordErrors.password
                  )?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getAuthorizedRepresentativeVerifyPasswordErrors.password)+" ")]):_vm._e()],1)],1),(
                _vm.getAuthorizedRepresentativeVerifyPasswordErrors &&
                _vm.getAuthorizedRepresentativeVerifyPasswordErrors.critical_error
              )?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getAuthorizedRepresentativeVerifyPasswordErrors.critical_error)+" ")]):_vm._e(),(
                _vm.getAuthorizedRepresentativeVerifyPasswordErrors &&
                _vm.getAuthorizedRepresentativeVerifyPasswordErrors.user_id
              )?_c('p',{staticClass:"error"},[_vm._v(" Invalid Link ")]):_vm._e(),(
                _vm.getAuthorizedRepresentativeVerifyPasswordErrors &&
                _vm.getAuthorizedRepresentativeVerifyPasswordErrors.employee_document_id
              )?_c('p',{staticClass:"error"},[_vm._v(" Invalid Link ")]):_vm._e(),(_vm.invalidLink)?_c('p',{staticClass:"error"},[_vm._v("Invalid Link")]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"success","round":"","disabled":_vm.invalidLink},on:{"click":_vm.verifyPassword}},[_vm._v(" Verify "),_c('i',{staticClass:"el-icon-right"})])],1)])])],1)],1),_c('p',{staticClass:"copyright-text text-grey-sm text-center mt-2"},[_vm._v(" All Rights Reserved 2020 © NimbleHRMS ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }